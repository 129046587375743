.infoWrapper{
  margin-bottom: 2em;
}
.infoRow{
  padding: 5px;
}
.comment{
  padding: 1em;
  border: 1px solid #999;
  margin: 1em;
  border-radius: 5px;
}
.comment .date{
  font-size: 0.7em;
  text-align: right;
}
.recom{
  padding: 1em;
  border: 1px solid #999;
  margin: 10px;
  position: relative;
}

.revoked{
  border: 2px solid red;
}
.revoked::after{
  color: red;
  content: "REVOKED";
  font-size: 50px;
  left: 25%;
  opacity: .4;
  position: absolute;
  rotate: -12deg;
  top: 40px;
  z-index: 1;
}
.caregiver {
  padding: 1em;
  border-top: 1px solid #333;
  margin-top: 1em;
}
.resize textarea{
  resize: vertical;
}